import { User } from '../User';

export const testEmployee: User = {
  id: '123',
  emailAddress: 'Jdoe@cj.com',
  firstName: 'Jane',
  lastName: 'Doe',
  isEmployee: true,
};

export const testNonEmployee: User = {
  id: '234',
  emailAddress: 'TCook@apple.com',
  firstName: 'Tim',
  lastName: 'Apple',
  isEmployee: false,
};
