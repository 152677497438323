import { Api } from '../../Api';
import { MainPage } from '../MainPage';
import {
  PlatformApp,
  useNavigate,
  User,
} from '@platform/cj-platform-navigation';
import '@cjdev-internal/design-tokens';

// design tokens are css variables that VSX depends on
export type AppDependencies = {
  api: Api;
  doc: Document;
};

export const App = (dependencies: AppDependencies) => {
  const layout = {
    filters: true,
    sidenav: true,
    dimensions: true,
    pageTitle: true,
    Buttons: true,
  };

  const routes = [
    {
      layout,
      path: '/',
      titleMessage: {
        id: 'nav-home',
        defaultMessage: 'Home',
      },
      Content: () => {
        const navigate = useNavigate();
        navigate('/list');
        return <></>;
      },
    },
    {
      layout,
      path: '/list',
      Buttons: () => <span id="createTagButtons"></span>,
      titleMessage: {
        id: 'nav-root',
        defaultMessage: 'Tag Management',
      },
      Content: ({ user }: { user: User }) => (
        <MainPage api={dependencies.api} doc={dependencies.doc} user={user} />
      ),
    },
  ];

  const translations = {};
  return (
    <>
      <PlatformApp routes={routes} translations={translations} />
    </>
  );
};
