import intl from '../../utils/intl';
import React from 'react';
import { defineMessages } from 'react-intl';
import { BlankPage as VSXBlankPage } from '@cjdev-internal/visual-stack-x/BlankPage';

interface BlankPageProps {
  setIsCreatingTag: () => void;
}

export const BlankPage = (props: BlankPageProps) => {
  return (
    <VSXBlankPage
      title={intl.formatMessage(content.blankPageHeader)}
      primaryButtonText={intl.formatMessage(content.createTagButton)}
      onPrimaryButtonClick={props.setIsCreatingTag}
    >
      {intl.formatMessage(content.blankPageText)}
    </VSXBlankPage>
  );
};

export const content = defineMessages({
  blankPageHeader: {
    id: 'atossa.blankPage.header',
    defaultMessage:
      "Integrate CJ's transaction tags for accurate conversion tracking",
  },
  blankPageText: {
    id: 'atossa.blankPage.text',
    defaultMessage: 'Create your first tag to get started',
  },
  createTagButton: {
    id: 'atossa.blankPage.createtag.button',
    defaultMessage: 'Create Tag',
  },
});
