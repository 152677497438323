import { UniversalTag } from './index';
import isObject from '../isObject';

export type CreateUniversalTag = Omit<UniversalTag, 'id'>;

export const isCreateUniversalTag = (
  object: unknown
): object is CreateUniversalTag => {
  const createUniversalTagRequest = object as CreateUniversalTag;
  return isObject(createUniversalTagRequest);
};
