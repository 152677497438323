import { User } from './User';

export interface TrackedValue<T> {
  value: T;
  updatedBy: User;
  updatedAt: number;
}

export const isTrackedValue = <T>(
  object: unknown
): object is TrackedValue<T> => {
  const trackedField = object as TrackedValue<T>;
  return (
    !!trackedField &&
    trackedField.value !== undefined &&
    trackedField.updatedBy !== undefined &&
    trackedField.updatedAt !== undefined
  );
};

export type UntrackedValue<T> = Pick<TrackedValue<T>, 'value'>;

export const isUntrackedValue = <T>(
  object: unknown
): object is UntrackedValue<T> => {
  const untrackedField = object as UntrackedValue<T>;
  return !!untrackedField && untrackedField.value !== undefined;
};

export type MaybeTrackedValue<T> = UntrackedValue<T> | TrackedValue<T>;
export const isMaybeTrackedValue = <T>(
  object: unknown
): object is MaybeTrackedValue<T> =>
  isUntrackedValue(object) || isTrackedValue(object);
