export type AffApiCompany = {
  id: number;
  name: string;
  type: 'advertiser' | 'publisher';
  status: 'ACTIVE' | unknown;
  functionalCurrency: 'USD' | unknown;
  companyRole: unknown;
};

export const isAffApiCompany = (object: unknown): object is AffApiCompany => {
  const company = object as AffApiCompany;
  return !!company && company.id !== undefined && company.name !== undefined;
};
