export type User = {
  id: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  isEmployee: boolean;
};

export const isUser = (object: unknown): object is User => {
  const user = object as User;
  return !!user && user.id !== undefined && user.isEmployee !== undefined;
};
