import React from 'react';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import { Body, Header, Panel } from '@cjdev-internal/visual-stack-x/Panel';
import { Button } from '@cjdev-internal/visual-stack-x/Button';
import { DisplayTrackedValue } from '../../utils/trackedValue';
import styled from 'styled-components';
import { IconSuccess } from '@cjdev-internal/visual-stack-x/Icons';

interface CjDataViewProps {
  cjDataEnabled: DisplayTrackedValue<boolean>;
  openToggleCjDataModal: () => void;
}

const CjDataViewStyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
`;

const smallerSize = '1.2rem';
const CjDataChangedUserSpan = styled.span`
  font-size: ${smallerSize};
`;

const StyledIconSuccess = styled(IconSuccess)`
  width: ${smallerSize};
  height: ${smallerSize};
  margin-right: 0.5rem;
`;

export const CjDataView = (props: CjDataViewProps) => {
  const { cjDataEnabled, openToggleCjDataModal } = props;

  return (
    <Panel style={{ textAlign: 'left' }}>
      <Header
        style={{
          borderBottom: 'none',
        }}
      >
        <CjDataViewStyledDiv>
          {intl.formatMessage(content.cjDataPanelHeader)}
          <Button type="secondary" onClick={openToggleCjDataModal}>
            {cjDataEnabled.value
              ? intl.formatMessage(content.cjDataDisableButton)
              : intl.formatMessage(content.cjDataEnableButton)}
          </Button>
        </CjDataViewStyledDiv>
        {cjDataEnabled.updatedBy === '' || cjDataEnabled.updatedAt === '' ? (
          <></>
        ) : (
          <CjDataChangedUserSpan>
            {cjDataEnabled.value ? (
              <StyledIconSuccess className="cj-green" />
            ) : (
              <></>
            )}
            {intl.formatMessage(
              cjDataEnabled.value
                ? content.cjDataEnableSince
                : content.cjDataDisableSince,
              {
                user: cjDataEnabled.updatedBy,
                date: cjDataEnabled.updatedAt,
              }
            )}
          </CjDataChangedUserSpan>
        )}
      </Header>
      <Body>
        <span>
          {intl.formatMessage(content.cjDataPanelBody, {
            cjdata: <code>cjdata</code>,
          })}
        </span>
      </Body>
    </Panel>
  );
};

export const content = defineMessages({
  cjDataPanelHeader: {
    id: 'atossa.cjData.panel.header',
    defaultMessage: 'CJ Data',
  },
  cjDataEnableButton: {
    id: 'atossa.cjData.enable.button',
    defaultMessage: 'Enable',
  },
  cjDataDisableButton: {
    id: 'atossa.cjData.disable.button',
    defaultMessage: 'Disable',
  },
  cjDataPanelBody: {
    id: 'atossa.cjData.panel.body',
    defaultMessage:
      'This feature allows CJ to pass information regarding GDPR consent through the {cjdata} parameter in order for the Universal Tag to behave in compliance with the regulation.',
  },
  cjDataDisableSince: {
    id: 'atossa.cjData.enable.since',
    defaultMessage: 'Disabled by {user} since {date}',
  },
  cjDataEnableSince: {
    id: 'atossa.cjData.disable.since',
    defaultMessage: 'Enabled by {user} since {date}',
  },
});
