import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import { Button } from '@cjdev-internal/visual-stack-x/Button';
import { Advertiser, TrackedValue, UniversalTag } from '@atossa/core';
import { CjDataView } from '../CjDataView';
import { readableUser, toDisplayTrackedValue } from '../../utils/trackedValue';
import {
  ActionMenuOptionDef,
  ColumnDefList,
  DataGrid,
} from '@cjdev-internal/visual-stack-x/DataGrid';
import { ToastOptions } from '@cjdev-internal/visual-stack-x/Toast';

export type DisplayUniversalTag = Partial<TrackedValue<UniversalTag>>;

interface AdvertiserTagViewProps {
  advertiser: Advertiser;
  showToast: (options: ToastOptions) => void;
  cjDataViewOpen: () => void;
  setTagUnderEdit: React.Dispatch<
    React.SetStateAction<UniversalTag | undefined>
  >;
  setIsCreatingTag: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdvertiserTagView = (props: AdvertiserTagViewProps) => {
  const { advertiser } = props;
  const rowsPerPage = 10;

  const createButton = (
    <Button type="primary" onClick={() => props.setIsCreatingTag(true)}>
      {intl.formatMessage(content.createTagButton)}
    </Button>
  );

  const [containerToLoad, setContainerToLoad] = useState<HTMLElement | null>(
    null
  );
  // If the target element is ready and loaded
  // set the target element
  useEffect(() => {
    setContainerToLoad(document.getElementById('createTagButtons'));
  }, []);

  const portal = containerToLoad && createPortal(createButton, containerToLoad);

  const data: DisplayUniversalTag[] = advertiser.tags.map((tag) => ({
    updatedAt: undefined,
    updatedBy: undefined,
    ...tag,
  }));

  const columnDefs: ColumnDefList<DisplayUniversalTag> = [
    {
      headerName: intl.formatMessage(content.tagTableHeaderId),
      field: 'value.id',
      flex: 10,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderName),
      field: 'value.name',
      flex: 20,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderWebsiteUrl),
      field: 'value.url',
      flex: 25,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderLastUpdated),
      field: 'updatedAt',
      type: 'date',
      flex: 20,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderUpdatedBy),
      valueGetter: (p) => `${readableUser(p.data?.updatedBy)}`,
      flex: 15,
    },
  ];
  const rowActions: ActionMenuOptionDef<DisplayUniversalTag> = (p) => [
    {
      label: intl.formatMessage(content.editTagButton),
      onClick: (e) => {
        props.setTagUnderEdit(e.rowData.value);
      },
    },
  ];

  return (
    <>
      {portal}
      <CjDataView
        cjDataEnabled={toDisplayTrackedValue(advertiser.cjDataEnabled)}
        openToggleCjDataModal={props.cjDataViewOpen}
      />
      <div>
        <DataGrid<DisplayUniversalTag>
          domLayout="autoHeight"
          title={intl.formatMessage(content.tagTableCaption)}
          columnDefs={columnDefs}
          pagination
          paginationPageSize={rowsPerPage}
          getRowId={(row) => row.value!.id}
          rowData={data}
          rowActions={rowActions}
        />
      </div>
    </>
  );
};

export const content = defineMessages({
  tagTableCaption: {
    id: 'atossa.tagTable.caption',
    defaultMessage: 'Tags',
  },
  tagTableHeaderId: {
    id: 'atossa.tagTable.table.header.id',
    defaultMessage: 'ID',
  },
  tagTableHeaderName: {
    id: 'atossa.tagTable.table.header.name',
    defaultMessage: 'Name',
  },
  tagTableHeaderWebsiteUrl: {
    id: 'atossa.tagTable.table.header.websiteUrl',
    defaultMessage: 'Website URL',
  },
  tagTableHeaderLastUpdated: {
    id: 'atossa.tagTable.table.header.lastUpdated',
    defaultMessage: 'Last Updated',
  },
  tagTableHeaderUpdatedBy: {
    id: 'atossa.tagTable.table.header.updatedBy',
    defaultMessage: 'Updated By',
  },
  tagTableHeaderActions: {
    id: 'atossa.tagTable.table.header.actions',
    defaultMessage: 'Actions',
  },
  tagTableDatatablePerPage: {
    id: 'atossa.tagTable.table.perPage',
    defaultMessage: '{0} per page',
  },
  tagTableDatatableTotalRecords: {
    id: 'atossa.tagTable.table.totalRecords',
    defaultMessage: '{0} total records',
  },
  createTagButton: {
    id: 'atossa.tagTable.createtag.button',
    defaultMessage: 'Create Tag',
  },
  editTagButton: {
    id: 'atossa.tagTable.edittag.button',
    defaultMessage: 'EDIT',
  },
});
