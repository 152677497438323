import { AffApiCompany, isAffApiCompany } from './Company';

export type AffApiUser = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  isEmployee: boolean;
  companies: AffApiCompany[];
};

export const isAffApiUser = (object: unknown): object is AffApiUser => {
  const user = object as AffApiUser;
  return (
    !!user &&
    user.isEmployee !== undefined &&
    Array.isArray(user.companies) &&
    user.companies.every(isAffApiCompany)
  );
};
