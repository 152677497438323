import isObject from '../isObject';

export type UpdateAdvertiser = { cjDataEnabled: boolean };

export const isUpdateAdvertiser = (
  object: unknown
): object is UpdateAdvertiser => {
  const updateCjData = object as UpdateAdvertiser;
  return isObject(updateCjData) && updateCjData.cjDataEnabled !== undefined;
};
