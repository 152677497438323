import * as R from 'ramda';
import { createIntl, createIntlCache } from 'react-intl';
import en from '../content/en.json';
import cs from '../content/cs.json';
import de from '../content/de.json';
import es from '../content/es.json';
import fr from '../content/fr.json';
import ja from '../content/ja.json';
import pl from '../content/pl.json';
import pt from '../content/pt.json';
import zh from '../content/zh.json';

const cache = createIntlCache();
const locale = R.toLower(R.take(2, navigator.language)) as
  | 'en'
  | 'cs'
  | 'de'
  | 'es'
  | 'fr'
  | 'ja'
  | 'pl'
  | 'pt'
  | 'zh'; // uses the navigator local, ex: en-US
const messages = { en, cs, de, es, fr, ja, pl, pt, zh }[locale];

const intl = createIntl(
  {
    locale,
    messages,
  },
  cache
);

export default intl;
