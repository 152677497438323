import { isUniversalTag, UniversalTag } from '../UniversalTag';
import { isMaybeTrackedValue, MaybeTrackedValue } from '../TrackedValue';

export interface Advertiser {
  cid: string;
  tags: MaybeTrackedValue<UniversalTag>[];
  cjDataEnabled: MaybeTrackedValue<boolean>;
}

export const isAdvertiser = (object: unknown): object is Advertiser => {
  const advertiser = object as Advertiser;
  return (
    !!advertiser &&
    advertiser.cid !== undefined &&
    advertiser.tags !== undefined &&
    advertiser.tags.every(
      (trackedTag) =>
        isMaybeTrackedValue(trackedTag) && isUniversalTag(trackedTag.value)
    ) &&
    advertiser.cjDataEnabled !== undefined
  );
};
